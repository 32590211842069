@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Red Hat Display", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

@layer base {
  html {
    @apply text-black;
  }

  body {
  }

  main {
    @apply text-black md:max-w-[1400px] mx-auto;
  }
}

@layer components {
  .button {
    @apply bg-primary text-white text-center rounded-md py-2 px-5 hover:bg-primary-600;
  }

  .button-outline {
    @apply text-primary border border-primary text-center rounded-md py-2 px-5 hover:bg-primary hover:text-white;
  }

  .text-input {
    @apply h-12 w-full px-4 rounded-lg border border-gray-400 focus:border-secondary focus:outline-none focus:ring-transparent;
  }

  .action-icon {
    @apply bg-[#213843] text-white rounded-full size-11 p-2 flex justify-center items-center mb-2;
  }

  .section-1 #event-descriptor {
    @apply absolute inline-block overflow-hidden -mt-[4px] md:mt-0;
  }

  .section-1 #event-descriptor .content {
    @apply overflow-hidden h-[44px] md:h-[56px];
  }

  .section-1 #event-descriptor .content ul li {
    @apply m-0 md:leading-[56px] leading-[44px] ml-[7px] md:ml-[9px];
  }

  .section-2 .business-tile {
    @apply bg-white rounded-3xl p-4 shadow-[5px_8px_0_0_rgb(0,168,143)] mx-3 md:mx-2;
  }

  .section-2 .business-tile .image {
    @apply size-52 md:size-44 min-w-52 min-h-52 bg-primary-400;
  }

  .section-2 .business-tile .text {
    @apply w-full text-center pt-2 text-lg;
  }

  .section-4 .customer-tile {
    @apply md:self-center min-w-72 md:w-80 md:min-w-64 md:h-auto relative bg-white rounded-3xl px-6 shadow-[5px_8px_0_0_rgb(33,56,67)] mx-3 md:mx-2 border border-black;
  }

  .section-4 .customer-tile .photo {
    @apply size-24 rounded-full bg-primary shadow-[3px_6px_0_1px_rgb(12,114,105)] absolute -top-12 left-0 right-0 mx-auto;
  }

  .section-4 .customer-tile .title {
    @apply text-secondary font-semibold text-xl text-center pt-14;
  }

  .section-4 .customer-tile .name {
    @apply font-black text-2xl text-center;
  }

  .section-4 .customer-tile .quote {
    @apply pt-6 text-center;
  }

  .section-4 .customer-tile .contact {
    @apply text-gray-400 text-center font-bold block py-4;
  }

  .section-5 .audience-tile {
    @apply flex md:block gap-2 flex-col md:max-w-80 self-center md:self-auto mx-4 rounded-3xl px-6 md:py-6 py-3 shadow-[5px_8px_0_0_rgb(33,56,67)] border border-black bg-white w-full max-w-sm;
  }

  .section-5 .title {
    @apply font-black py-2 order-1 md:text-left text-center md:mt-2 md:order-2 text-[20px];
  }

  .section-5 .audience-tile .image {
    @apply w-full md:w-[290px] rounded-lg order-2 md:order-1;
  }

  .section-5 .audience-tile .description {
    @apply order-3 md:text-left text-center py-2;
  }

  .section-7 .slope {
    width: 0;
    height: 500px;
    position: relative;
  }

  .section-7 .slope:before {
    content: "";
    position: absolute;
    display: block;
    right: -150px;
    bottom: 0px;
    width: 0px;
    height: 0px;
    border-top: solid 500px #fff;
    border-right: solid 150px transparent;
    background-color: #ebf8f6;
  }

  .pricing .section-1 .tile {
    @apply bg-white rounded-3xl p-5 border border-darkBlue md:min-w-80 md:max-w-80 shadow-[4px_6px_0_0_rgb(33,56,67)] flex flex-col;
  }

  .pricing .section-1 .tile .banner {
    @apply md:shadow-none md:static absolute -top-8 -left-8 flex justify-center items-center md:block rounded-full size-16 md:w-full md:h-auto md:rounded-xl text-lg md:text-5xl text-white font-bold text-center md:py-7;
  }

  .pricing .section-1 .tile .headline {
    @apply text-center font-black md:mt-3 text-xl;
  }
}
